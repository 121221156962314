import React, { useRef, useContext, useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Container,
  Card,
  Row,
  Tab,
  Nav,
  Accordion,
  useAccordionToggle,
  AccordionContext,
} from "react-bootstrap"
import bell from "../images/bell.png"
import fcar1 from "../images/howToJoin/car1.png"
import fcar2 from "../images/howToJoin/car2.png"
import fcar3 from "../images/howToJoin/car3.png"
import fcar4 from "../images/howToJoin/car4.png"
import htj from "../images/howToJoin/panoramicImage.png"
import accIcon from "../images/acc-icon.png"
import ban1 from "../images/howToJoin/htjBanner1.png"
import Slider from "react-slick"
import AOS from "aos"
import "aos/dist/aos.css"
import { AnchorLink } from "gatsby-plugin-anchor-links"

// eslint-disable-next-line react/prop-types
function ContextAwareToggle({ children, eventKey, callback }) {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey
  return (
    <div
      type="button"
      className={isCurrentEventKey ? "show" : ""}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  )
}

const HowToJoin = () => {
  const car = useRef()
  const settingsCar = {
    dots: true,
    dotsClass: "slick-dots fel-indi ban-indi laf-fel-indi",
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  return (
    <Layout pageName="How To Join">
      <SEO title="How To Join" />
      <Row className="mx-0 z-50">
        <div
          className="col-lg-6 order-lg-last px-0 halfwayBannerColor"
          data-aos="fade"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <Slider {...settingsCar} className="laf-fel" ref={car}>
            <img className="w-100" src={ban1} />
          </Slider>
        </div>
        <div className="col-lg-6 bg-sec align-center">
          <div className="jtf-cont">
            <div className="text-center text-lg-left">
              <p
                className="ed-title"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Join the movement
              </p>
              <p
                className="jtf-head mb-lg-3 mb-3"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="1000"
              >
                Stand Up For India
              </p>
              <p
                className="gentxt  mb-lg-3 mb-4"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
              >
                Looking to join a coalition of extraordinary trailblazers? Teach
                For India has cultivated amazing change agents for nearly 12
                years. Learn how you can join our community of talented
                professionals and transform lives. This is an opportunity to
                lead Self, Others and India towards educational equity.
              </p>
              <div
                className="mb-4 mb-lg-0"
                data-aos="fade-up"
                data-aos-delay="900"
                data-aos-duration="1000"
              >
                <a
                  href="https://apply.teachforindia.org/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="butn butn-spl butn-main mr-3"
                >
                  APPLY ONLINE
                </a>
                <AnchorLink
                  to="/how-to-join#eligibility"
                  title="Eligibility Criteria"
                  className="uc-link"
                  stripHash
                />
              </div>
            </div>
          </div>
        </div>
      </Row>
      <Container className="z-999 text-center">
        <div
          className=""
          data-aos="fade"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <img className="bell-icon" src={bell} />
        </div>
      </Container>
      <Container>
        <div className="text-center pb-lg-5 max-80 max-sm-100 mx-auto">
          <p
            className="ed-title"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            What the Fellowship will do for you
          </p>
          <p
            className="fi-head mt-3 mt-lg-0"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-duration="1000"
          >
            While transforming classrooms, you will cultivate the knowledge,
            skills and mindsets required to become a leader
          </p>
        </div>
      </Container>
      <Container className="py-lg-5 inverted">
        <Row className="pb-5 pt-lg-5 pt-0 whatsnew">
          <div
            className="col-lg-7 pr-lg-5 wn-img"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <img src={fcar1} className="w-100" />
          </div>
          <div
            className="col-lg-5 wn-cont text-center text-lg-left mt-3 mt-lg-0 valign-center"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-duration="1000"
          >
            <div className="sonet">
              <p className="iwn-title">Access to a Wide Professional Network</p>
              <p className="fi-txt pb-4 pb-lg-0">
                Fellows have a chance to connect with other development sector
                professionals. They are also supported by Teach For India Staff
                in sourcing post-Fellowship opportunities through an external
                career fest.
              </p>
            </div>
          </div>
        </Row>
        <Row className="py-5 whatsnew">
          <div
            className="col-lg-7 pr-5 wn-img"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <img src={fcar2} className="w-100" />
          </div>
          <div
            className="col-lg-5 wn-cont text-center text-lg-left mt-3 mt-lg-0 valign-center"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-duration="1000"
          >
            <div className="sonet">
              <p className="iwn-title">Developing Skills for Tomorrow</p>
              <p className="fi-txt pb-4 pb-lg-0">
                Fellows develop leadership skills such as communication,
                problem-solving in an unstructured environment, people
                management, investment of stakeholders and resourcefulness. They
                are empowered to embark on their leadership development journey
                with the lens of leading Self, Others and India.
              </p>
            </div>
          </div>
        </Row>
        <Row className="py-5 whatsnew">
          <div
            className="col-lg-7 pr-5 wn-img"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <img src={fcar3} className="w-100" />
          </div>
          <div
            className="col-lg-5 wn-cont text-center text-lg-left mt-3 mt-lg-0 valign-center"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-duration="1000"
          >
            <div className="sonet">
              <p className="iwn-title">Sustained Learning</p>
              <p className="fi-txt pb-4 pb-lg-0">
                Fellows receive sustained mentorship in the form of a dedicated
                Program Manager. Teach For India also provides on-going
                technical training, and interactive workshops for personal
                development both from in-house trainers, and outside experts
                through the course of the Fellowship.
              </p>
            </div>
          </div>
        </Row>
        <Row className="py-5 whatsnew">
          <div
            className="col-lg-7 pr-5 wn-img"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <img src={fcar4} className="w-100" />
          </div>
          <div
            className="col-lg-5 wn-cont text-center text-lg-left mt-3 mt-lg-0 valign-center"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-duration="1000"
          >
            <div className="sonet">
              <p className="iwn-title">Become an Innovator</p>
              <p className="fi-txt pb-4 pb-lg-0">
                Teach For India offers in-depth training in classroom values and
                pedagogy, which provides the support to innovate and pilot your
                own projects for change. Through the BTCP (Be The Change
                Projects), you will explore, develop and lead your own
                solutions.
              </p>
            </div>
          </div>
        </Row>
      </Container>
      <div className="py-lg-5 mt-lg-5">
        <img
          src={htj}
          className="w-100"
          data-aos="fade"
          data-aos-delay="100"
          data-aos-duration="1000"
        />
      </div>
      <Container className="py-5">
        <p
          className="ed-title text-center text-lg-left"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          Things to know before you apply
        </p>
        <Row>
          <div
            className="col-lg-6"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <p className="fs-30 semibold sm-bold fs-sm-24 text-center text-lg-left">
              If you hold a graduate degree and want to be a part of the
              solution, the Fellowship is the place for you
            </p>
          </div>
          <div
            className="col-lg-6 align-center px-lg-5 text-center text-lg-left"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <p className="fi-title mb-0">
              We are looking for passionate, driven individuals who hold a
              graduate degree and have exhibited academic proficiency,
              leadership, strong organisational skills and who are inclined to
              making a commitment to the communities we serve.
            </p>
          </div>
        </Row>
      </Container>

      <div id="eligibility" className="bg-sm-sec py-5" name="eligibility ">
        <Container>
          <div className="text-center">
            <p
              className="wn-title mb-1"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              Eligibility
            </p>
            <p
              className="gentxt"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              Here are the eligibility criteria in detail:
            </p>
            <div className="text-left mb-5 d-block d-lg-none">
              <Accordion className="eleg pt-lg-5" defaultActiveKey="0">
                <Card
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  <Card.Header>
                    <ContextAwareToggle eventKey="0">
                      <p className="eleg-title">
                        Minimum Qualification
                        <span className="acc-plus-icon">+</span>
                        <span className="acc-minus-icon">-</span>
                      </p>
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p className="ed-title">
                        You must have completed all coursework required for your{" "}
                        <strong>
                          undergraduate degree (Bachelor’s degree equivalent)
                        </strong>{" "}
                        from an accredited college or university by the last
                        week of May in the year of applying (For example, if
                        you’re applying to the Fellowship in 2021, your course
                        credits must be completed by May 2021).
                      </p>
                      <p className="ed-title">
                        Your degree transcripts (graduate/ postgraduate
                        certificates) will be reviewed before a decision can be
                        taken. Applicants must also pass any coursework
                        indicated in their transcripts at the time of the
                        interview.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  <Card.Header>
                    <ContextAwareToggle eventKey="1">
                      <p className="eleg-title">
                        Nationality Criteria
                        <span className="acc-plus-icon">+</span>
                        <span className="acc-minus-icon">-</span>
                      </p>
                    </ContextAwareToggle>
                  </Card.Header>

                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p className="ed-title sped">
                        You should be either-
                        <br />
                        <strong>(i) an Indian citizen</strong>
                        <br /> or
                        <br />
                        <strong>
                          (ii) a foreign national of Indian origin.
                        </strong>
                        <br /> Foreign citizens who are not of Indian origin are
                        not eligible to apply. Note to Foreign nationals of
                        Indian origin: For more information on the OCI card,
                        please go to the{" "}
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://ociservices.gov.in/"
                        >
                          Ministry of Home Affairs.{" "}
                        </a>
                        To apply for the OCI card, please go to the Online OCI
                        Registration Form. We recommend applying for the OCI
                        card as soon as you have made the decision to join the
                        Fellowship, as it takes approximately 1-3 months to
                        receive the card.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  <Card.Header>
                    <ContextAwareToggle eventKey="2">
                      <p className="eleg-title">
                        Commitment & Location
                        <span className="acc-plus-icon">+</span>
                        <span className="acc-minus-icon">-</span>
                      </p>
                    </ContextAwareToggle>
                  </Card.Header>

                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <p className="ed-title">
                        The Fellowship is a{" "}
                        <strong>full-time, paid commitment</strong> to teaching
                        and living in one of our placement cities (Ahmedabad,
                        Bengaluru, Chennai, Delhi, Hyderabad, Mumbai, Pune)
                        starting May/ June 2021. Fellows are full-time staff
                        members of the schools in which they are placed. They
                        work stipulated school hours six days a week as well as
                        attend training and support sessions as per city
                        requirements.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  <Card.Header>
                    <ContextAwareToggle eventKey="3">
                      <p className="eleg-title">
                        English Fluency
                        <span className="acc-plus-icon">+</span>
                        <span className="acc-minus-icon">-</span>
                      </p>
                    </ContextAwareToggle>
                  </Card.Header>

                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <p className="ed-title">
                        You should be
                        <strong>fluent in the English language</strong> as Teach
                        For India Fellows are placed in English-medium schools
                        and are expected to teach in and attend training
                        sessions in English.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  <Card.Header>
                    <ContextAwareToggle eventKey="4">
                      <p className="eleg-title">
                        Age Limit
                        <span className="acc-plus-icon">+</span>
                        <span className="acc-minus-icon">-</span>
                      </p>
                    </ContextAwareToggle>
                  </Card.Header>

                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <p className="ed-title">
                        There is no age limit to apply to the Teach For India
                        Fellowship.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>

            <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
              <a
                href="https://apply.teachforindia.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="butn butn-main mr-3"
              >
                Apply Online
              </a>
              <AnchorLink
                to="/how-to-join#application-process"
                title="Selection process"
                className="uc-link"
                stripHash
              />
            </p>
          </div>
          <div
            className="col-lg-12 pt-lg-5 d-none d-lg-block"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row className="tabContaier">
                <div className="col-lg-12 px-0">
                  <Nav className="tabNav">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        Minimum <br />
                        Qualification
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        Nationality <br />
                        Criteria
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        Commitment <br />& Location
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fourth">
                        English
                        <br />
                        Fluency
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fifth">
                        Age <br />
                        Limit
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>

                <div className="col-lg-12 px-0 tabContainer">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <p className="ed-title">
                        You must have completed all coursework required for your{" "}
                        <strong>
                          undergraduate degree (Bachelor’s degree equivalent)
                        </strong>{" "}
                        from an accredited college or university by the last
                        week of May in the year of applying (For example, if
                        you’re applying to the Fellowship in 2021, your course
                        credits must be completed by May 2021).
                      </p>
                      <p className="ed-title">
                        Your degree transcripts (graduate/ postgraduate
                        certificates) will be reviewed before a decision can be
                        taken. Applicants must also pass any coursework
                        indicated in their transcripts at the time of the
                        interview.
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p className="ed-title sped">
                        You should be either-
                        <br />
                        <strong>(i) an Indian citizen</strong>
                        <br /> or
                        <br />
                        <strong>
                          (ii) a foreign national of Indian origin.
                        </strong>
                        <br /> Foreign citizens who are not of Indian origin are
                        not eligible to apply. Note to Foreign nationals of
                        Indian origin: For more information on the OCI card,
                        please go to the{" "}
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://ociservices.gov.in/"
                        >
                          Ministry of Home Affairs.{" "}
                        </a>
                        To apply for the OCI card, please go to the Online OCI
                        Registration Form. We recommend applying for the OCI
                        card as soon as you have made the decision to join the
                        Fellowship, as it takes approximately 1-3 months to
                        receive the card.
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p className="ed-title">
                        The Fellowship is a{" "}
                        <strong>full-time, paid commitment</strong> to teaching
                        and living in one of our placement cities (Ahmedabad,
                        Bengaluru, Chennai, Delhi, Hyderabad, Mumbai, Pune)
                        starting May/ June 2021. Fellows are full-time staff
                        members of the schools in which they are placed. They
                        work stipulated school hours six days a week as well as
                        attend training and support sessions as per city
                        requirements.
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <p className="ed-title">
                        You should be{" "}
                        <strong>fluent in the English language</strong> as Teach
                        For India Fellows are placed in English-medium schools
                        and are expected to teach in and attend training
                        sessions in English.
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">
                      <p className="ed-title">
                        There is <strong>no age limit</strong> to apply to the
                        Teach For India Fellowship.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </div>
      <Container className="py-5" id="application-process">
        <div className="text-center pt-lg-5">
          <p
            className="ed-title"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Application Process
          </p>
          <p
            className="fi-head"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            How to apply:
          </p>
        </div>
        <Accordion className="htj-acc pt-lg-5" defaultActiveKey="0">
          <Card
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <Card.Header>
              <ContextAwareToggle eventKey="0">
                <div className="acc-header teal">
                  <img src={accIcon} />
                  <p className="acc-index">1</p>
                  <p className="acc-header-title">Let’s begin</p>
                  <p className="acc-header-head">Online Application</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <p className="ed-title sped">
                  Your application will help us understand your experiences, and
                  motivations to join Teach For India, and why you would be a
                  strong fit for the Fellowship.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <Card.Header>
              <ContextAwareToggle eventKey="1">
                <div className="acc-header yellow">
                  <img src={accIcon} />
                  <p className="acc-index">2</p>
                  <p className="acc-header-title">Post Application</p>
                  <p className="acc-header-head">AMCAT Online Test</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>

            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <p className="ed-title">
                  This section gets unlocked within minutes of submitting your
                  application. The AMCAT is a 1-hour online test (AMCAT), which
                  focuses on your situational judgement and English language
                  ability. This must be completed within 3 days or 72 hours from
                  the time you submit your application form.
                  <strong>
                    Your application will not be considered complete until you
                    complete the test.
                  </strong>
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <Card.Header>
              <ContextAwareToggle eventKey="2">
                <div className="acc-header orange">
                  <img src={accIcon} />
                  <p className="acc-index">3</p>
                  <p className="acc-header-title">Post AMCAT Completion</p>
                  <p className="acc-header-head">Phone Review</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <p className="ed-title">
                  After we have reviewed your application form and AMCAT test
                  results, we may have some additional questions for you. If so,
                  you will be invited to attend a 30-minute phone interview with
                  one of our team members. Here we will ask follow-up questions
                  to gain further information that will be helpful in our
                  evaluation. However, if all aspects of your application are
                  clear to the reviewer, you may proceed directly to the
                  Assessment Centre.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <Card.Header>
              <ContextAwareToggle eventKey="3">
                <div className="acc-header magenta">
                  <img src={accIcon} />
                  <p className="acc-index">4</p>
                  <p className="acc-header-title">Post AMCAT Completion</p>
                  <p className="acc-header-head">Assessment Centre</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>

            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <p className="ed-title">
                  If successful in your application, you will be invited to the
                  final stage of our selection process- an in-person Assessment
                  Centre. At the Assessment Centre, we go through the following
                  steps:
                  <br />- A 5-minute teaching sample: we supply you with a range
                  of topics and grade levels (e.g., 6th grade Science, 2nd-grade
                  Maths), and have you ‘teach’ a class.
                  <br />- A Group Discussion
                  <br />- A Critical Thinking Activity
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <Card.Header>
              <ContextAwareToggle eventKey="4">
                <div className="acc-header green">
                  <img src={accIcon} />
                  <p className="acc-index">5</p>
                  <p className="acc-header-title">You are almost there. </p>
                  <p className="acc-header-head">One on one interview</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>

            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <p className="ed-title">
                  Only if you clear the first 3 activities OR based on your
                  performance in the first 3 activities, you are invited to the
                  “one on one” interview. This is an hour-long personal
                  interview with one of our Staff members. If you are based
                  abroad and cannot attend one of our in-person Assessment
                  Centres, we will conduct your interview and relevant
                  activities over Skype. You will receive more details for this
                  stage closer to the time.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <Card.Header>
              <ContextAwareToggle eventKey="5">
                <div className="acc-header dark-green">
                  <img src={accIcon} />
                  <p className="acc-index">6</p>
                  <p className="acc-header-title">Welcome to Teach For India</p>
                  <p className="acc-header-head">Acceptance and Placement</p>
                  <span className="acc-plus-icon">+</span>
                  <span className="acc-minus-icon">-</span>
                </div>
              </ContextAwareToggle>
            </Card.Header>

            <Accordion.Collapse eventKey="5">
              <Card.Body>
                <p className="ed-title">
                  Once you have successfully cleared all three stages of
                  Selection, you are accepted to the Fellowship. You will
                  receive communication on the next steps of the process, and
                  have opportunities to immerse yourself in the context of Teach
                  For India and the city in which you are placed. Accepts of the
                  Fellowship will have constant access to assistance and
                  resources from the organisation, as per their need- and will
                  be regularly communicated with on their journey ahead.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <div className="text-center pt-lg-5 d-none d-lg-block">
          <a
            href="https://apply.teachforindia.org/"
            target="_blank"
            rel="noopener noreferrer"
            className="butn butn-main"
          >
            Apply Online
          </a>
        </div>
      </Container>
    </Layout>
  )
}

export default HowToJoin
